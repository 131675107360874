import { css } from "@emotion/core"
import { btn } from "css/primitives"
import * as resource from "css/resource"
import { colors } from "css/theme"
import RemoveIcon from "images/svg/icons/remove.svg"
import React, { useState } from "react"
import Modal from "react-modal"
import { If } from "src/components"
import useHubspotForm from "src/hooks/use-hubspot-form"
import * as util from "src/util"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "100%",
  },
}

const modal = css`
  z-index: 10;
  &:focus {
    outline: none;
  }
  border-radius: 8px;
`

const modalButton = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    display: block;
    transition: box-shadow 0.3s ease;
    &:hover {
      color: white;
      box-shadow: 1px 0 20px 0 rgba(0, 0, 0, 0.3);
    }
  `,
]

const modalDescription = css`
  max-width: 460px;
  font-size: 18px;
  margin-top: -1rem;
  line-height: 1.77;
  opacity: 0.75;
  margin-bottom: 20px;
`

const formModalWrapper = css`
  width: 460px;
  overflow: auto;
  &:focus {
    outline: none;
  }
  @media (max-width: 479px) {
    width: 95vw;
    & > div {
      width: 100% !important;
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const closeButton = css`
  box-shadow: none;
  border: 0;
  background-color: transparent;
  fill: rgba(0, 0, 0, 0.7);
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  padding: 0.5rem;
  top: 0.5rem;
  right: 0.5rem;
  svg {
    transition: 0.2s ease fill;
  }
  &:hover,
  &:focus {
    fill: black;
    outline: none;
  }
`

export default function DemoModal({ closeModal }) {
  const [hasSubmitted, setSubmitted] = useState(false)
  const { error } = useHubspotForm({
    formId: "6300436b-19a4-422e-bb79-6025390b1b90",
    target: "#demo-form",
    onFormSubmitted: () => {
      util.trackEvent("ANY_FORM_SUBMISSION", "Submit")
      setSubmitted(true)
    },
  })
  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel="Demo Modal"
      css={modal}
      style={customStyles}
      ariaHideApp={false}
    >
      <button type="button" onClick={closeModal} css={closeButton}>
        <RemoveIcon width={20} height={20} />
      </button>
      <div>
        <div id="form" css={formModalWrapper}>
          <div
            css={resource.formWrapper}
            style={hasSubmitted ? { display: "none" } : null}
          >
            <h3 css={resource.formHeading}>Get a Demo</h3>
            <div id="demo-form" className="hs-form-wrapper">
              {error ? "Could not load form" : null}
            </div>
          </div>
          <If test={hasSubmitted}>
            <div css={resource.formWrapper}>
              <h2 css={resource.formHeading}>Thanks!</h2>
              <p css={modalDescription}>
                A deception campaign strategist will reach out to schedule a
                time shortly.
              </p>
              <button
                type="button"
                css={modalButton}
                onClick={() => {
                  setSubmitted(false)
                  closeModal()
                }}
              >
                Continue Browsing
              </button>
            </div>
          </If>
        </div>
      </div>
    </Modal>
  )
}
