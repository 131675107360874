import { css } from "@emotion/core"
import { colors } from "css/theme"
import { graphql, Link } from "gatsby"
import React, { useReducer } from "react"
import _get from "lodash/get"

import { logError } from "src/util"
import "src/queries/featured_media"
import { If } from "src/components"
import Footer from "src/components/Footer"
import SEO from "../../components/seo"
import ogImage from "images/active-defense/banking-finance/hero-image.jpg"
import Logo from "images/logo.png"

import Features from "../../views/deception-active-defense/banking-finance/Features"
import BankCaseStudies from "../../views/deception-active-defense/banking-finance/BankCaseStudies"
import ConsiderSection from "../../views/deception-active-defense/banking-finance/ConsiderSection"
import ActiveDefenseWorkshop from "../../views/deception-active-defense/banking-finance/ActiveDefenseWorkshop"
import DemoModal from "../../views/deception-active-defense/banking-finance/DemoModal"
import ResourceModal from "../../views/deception-active-defense/banking-finance/ResourceModal"

const wrapper = css`
  overflow: hidden;
  background-color: white;
`

const wrapperTop = css`
  background-image: url(${ogImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 37px;
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  @media (max-width: 576px) {
    padding-bottom: 45px;
  }
`

const headerLogo = css`
  margin-bottom: 108px;
  display: block;
  @media (max-width: 576px) {
    margin-bottom: 60px;
  }
`

const wrapperContent = css`
  width: 100%;
  max-width: 1125px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const heading = css`
  font-family: var(--heading);
  font-weight: bold;
  font-size: 36px;
  color: white;
  margin-bottom: 27px;
  max-width: 520px;
  @media (max-width: 767px) {
    font-size: 32px;
  }
`

const headingText = css`
  color: white;
  font-size: 18px;
  line-height: 1.44;
  margin-bottom: 39px;
  max-width: 520px;
`

const btn = css`
  display: inline-block;
  font-family: var(--heading);
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  padding: 12px 30px;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  border: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 479px) {
    width: 100%;
  }
`

const buttons = css`
  button {
    margin-right: 17px;
    margin-bottom: 17px;
  }
`

const primaryButton = [
  btn,
  css`
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    color: white;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.9;
    }
  `,
]

const secondaryButton = [
  btn,
  css`
    border: 1px solid gray;
    background-color: transparent;
    color: white;
    transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
    &:hover {
      border: 1px solid gray;
      background-color: gray;
    }
  `,
]

const initialState = {
  isDemoModalOpen: false,
  isResourceModalOpen: false,
  resouceModalData: {},
}

function reducer(state, action) {
  switch (action.type) {
    case "open_demo_modal":
      return { ...state, isDemoModalOpen: true }
    case "close_demo_modal":
      return { ...state, isDemoModalOpen: false }
    case "open_resource_modal":
      return {
        ...state,
        isResourceModalOpen: true,
        resouceModalData: action.payload,
      }
    case "close_resouce_modal":
      return {
        ...state,
        isResourceModalOpen: false,
        resourceModalData: {},
      }
    default:
      throw new Error()
  }
}

const IndexPage = ({ data }) => {
  const heroImage = _get(data, "heroImage.childImageSharp.fluid") || {}
  const [state, dispatch] = useReducer(reducer, initialState)
  const openResourceModal = (payload) => {
    if (!payload) {
      logError("Case study is missing")
    }
    dispatch({ type: "open_resource_modal", payload })
  }
  const openDataSheetModal = () =>
    openResourceModal({
      ...data.main_case_study,
      heading: "Get the data sheet",
    })
  const openDemoModal = () => {
    dispatch({ type: "open_demo_modal" })
  }
  return (
    <>
      <SEO
        title="Deception and active defense for banks"
        description="Detect and stop serious threats that target customer data and core banking infrastructure. Reduce dwell-time, cut false positives, and contain threats at wire-speed."
        image={ogImage}
      />
      <div css={wrapper}>
        <div css={wrapperTop}>
          <div css={wrapperContent}>
            <Link to="/" css={headerLogo}>
              <img src={Logo} alt="Go To Home" />
            </Link>
            <h1 css={heading}>
              It’s time to actively defend. Start with deception.
            </h1>
            <p css={headingText}>
              Detect and stop serious threats that target customer data and core
              banking infrastructure. Reduce dwell-time, cut false positives,
              and contain threats at wire-speed.
            </p>
            <div css={buttons}>
              <button
                type="button"
                css={primaryButton}
                onClick={openDataSheetModal}
              >
                Download now
              </button>
              <button
                type="button"
                css={secondaryButton}
                onClick={openDemoModal}
              >
                Get a demo
              </button>
            </div>
          </div>
        </div>
        <Features
          openDemoModal={openDemoModal}
          openDataSheetModal={openDataSheetModal}
          image={heroImage}
        />
        <BankCaseStudies data={data} openResourceModal={openResourceModal} />
        <ConsiderSection openDemoModal={openDemoModal} />
        <ActiveDefenseWorkshop />
        <If test={state.isDemoModalOpen}>
          <DemoModal
            closeModal={() => dispatch({ type: "close_demo_modal" })}
          />
        </If>
        <If test={state.isResourceModalOpen}>
          <ResourceModal
            closeModal={() => dispatch({ type: "close_resouce_modal" })}
            data={state.resouceModalData}
          />
        </If>
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query BankingFinanceCaseStudiesActiveDefense {
    bse_case_study: wordpressWpMedia(wordpress_id: { eq: 18248 }) {
      id
      source_url
      title
    }
    bank_case_study: wordpressWpMedia(wordpress_id: { eq: 18617 }) {
      id
      source_url
      title
    }
    atm_case_study: wordpressWpMedia(wordpress_id: { eq: 18253 }) {
      id
      source_url
      title
    }
    main_case_study: wordpressWpMedia(wordpress_id: { eq: 18615 }) {
      id
      source_url
      title
    }
    heroImage: file(relativePath: { eq: "homepage/hero-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
