import React from "react"
import { css } from "@emotion/core"
import { colors } from "css/theme"

import { FluidImage } from "src/components"

const wrapper = css`
  background-image: linear-gradient(to bottom, #fafafa 4%, #f3f3f3);
  padding-top: 104px;
  padding-bottom: 90px;

  @media (max-width: 1023px) {
    padding-top: 3rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 45px;
  }
`

const wrapperInner = css`
  max-width: 1125px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  @media (max-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const imageWrapper = css`
  margin-right: 105px;
  .gatsby-image-wrapper {
    width: 100%;
    min-width: 556px;
    max-width: 556px;
  }
  @media (max-width: 1199px) {
    margin-right: 1rem;
  }
  @media (max-width: 1023px) {
    margin-bottom: 3rem;
    width: 100%;
    .gatsby-image-wrapper {
      min-width: 0;
    }
  }
`

const contentWrapper = css`
  @media (max-width: 1023px) {
    max-width: 700px;
  }
`

const heading = css`
  font-family: var(--heading);
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.31;
  color: black;
  margin-bottom: 0.75rem;

  @media (max-width: 479px) {
    font-size: 1.5rem;
  }
`

const features = css`
  list-style-type: none;
  margin-left: 0;
`

const feature = css`
  font-size: 1rem;
  line-height: 24px;
  color: black;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 33px;
  }
`

const featureTitle = css`
  font-weight: bold;
  color: #037c8a;
`

const btn = css`
  display: inline-block;
  font-family: var(--heading);
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  padding: 12px 30px;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  border: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 479px) {
    width: 100%;
  }
`

const buttons = css`
  button {
    margin-right: 17px;
    margin-bottom: 17px;
  }
`

const primaryButton = [
  btn,
  css`
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    color: white;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  `,
]

const secondaryButton = [
  btn,
  css`
    border: 1px solid #0c0c0c;
    color: black;
    background-color: transparent;
    transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
    &:hover {
      color: white;
      border: 1px solid gray;
      background-color: gray;
    }
  `,
]

function Features({ openDemoModal, openDataSheetModal, image }) {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={imageWrapper}>
          <FluidImage
            fluid={image}
            alt="Smokescreen investigate dashboard with attacker detail view open"
          />
        </div>
        <div css={contentWrapper}>
          <h2 css={heading}>
            No non-sense threat detection that doesn’t break the bank
          </h2>
          <ul css={features}>
            <li css={feature}>
              <strong css={featureTitle}>Detection:</strong> Stop threats in
              your network, endpoints, cloud, and identity management systems.
            </li>
            <li css={feature}>
              <strong css={featureTitle}>Containment:</strong> Out-of-the-box
              Integrations with SIEMs, Firewalls, EDRs, NACs, Sandboxes, and
              SOARs.
            </li>
            <li css={feature}>
              <strong css={featureTitle}>Threat intelligence:</strong> Get
              actionable intelligence from the world’s largest network mesh of
              decoys.
            </li>
            <li css={feature}>
              <strong css={featureTitle}>Enterprise features:</strong> Forensics
              triage, automatic backup, log retention, 2FA, and training videos.
            </li>
          </ul>
          <div css={buttons}>
            <button
              type="button"
              css={primaryButton}
              onClick={openDataSheetModal}
            >
              Download now
            </button>
            <button type="button" css={secondaryButton} onClick={openDemoModal}>
              Get a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
