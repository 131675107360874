import React from "react"
import { css } from "@emotion/core"
import { colors } from "css/theme"

import Sahir from "images/active-defense/banking-finance/sahir.jpg"

const wrapper = css`
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #ffffff 59%);
  padding-top: 81px;
  padding-bottom: 80px;
  @media (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const wrapperInner = css`
  max-width: 1125px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const wrapperContent = css`
  padding-right: 1rem;
`

const heading = css`
  font-family: var(--heading);
  line-height: 1.31;
  color: black;
  font-weight: 600;
  font-size: 2rem;
  max-width: 534px;
  margin-bottom: 13px;
  strong {
    color: #238c98;
    font-weight: 600;
  }
  @media (max-width: 576px) {
    font-size: 1.7rem;
  }
`

const text = css`
  font-size: 1rem;
  line-height: 1.75;
  color: black;
  max-width: 641px;
  p {
    margin-bottom: 28px;
  }
  ul {
    margin-bottom: 47px;
    @media (max-width: 576px) {
      margin-bottom: 2rem;
    }
  }
  li {
    margin-bottom: 0.2rem;
  }
  li::marker {
    color: #238c98;
  }
`

const btn = css`
  display: inline-block;
  font-family: var(--heading);
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  padding: 11px 30px;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  border: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
`

const primaryButton = [
  btn,
  css`
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    color: white;
    min-width: 244px;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
      color: white;
    }
  `,
]

const instructorSection = css`
  text-align: center;
  margin-left: auto;
  @media (max-width: 767px) {
    margin-left: 0;
    text-align: left;
    margin-top: 2rem;
  }
`

const instructorImage = css`
  width: 308px;
  height: 308px;
  border-radius: 50%;
  overflow: hidden;
  background: #dedede;
  margin-bottom: 1rem;
  img {
    object-fit: "cover";
    object-position: center;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 567px) {
    width: 50%;
    height: 50%;
  }
`
const instructorSubtitle = css`
  font-size: 14px;
  font-family: var(--heading);
  color: black;
  line-height: 18px;
  margin-bottom: 12px;
`

const instructorName = css`
  font-family: var(--heading);
  font-size: 20px;
  font-weight: 600;
  color: black;
  height: 24px;
  margin-bottom: 6px;
`

const instructorDesignation = css`
  color: #238c98;
  font-size: 14px;
  font-family: var(--heading);
  text-transform: uppercase;
  line-height: 1.57;
  font-weight: 500;
`

export default function Features() {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={wrapperContent}>
          <div css={heading}>
            <strong>Not Ready Yet?</strong>
            <br />
            Join our MITRE Shield Active Defense Workshop
          </div>
          <div css={text}>
            <p>
              MITRE’s new active defense knowledge base called Shield can has
              the potential to transform how you deal with adversaries and
              mitigate risks. During the workshop, we will:
            </p>
            <ul>
              <li>
                Deconstruct MITRE shield and align it with your security
                priorities.
              </li>
              <li>Cover active defense strategies not present in Shield.</li>
              <li>Breakdown how you can get started with Active Defense.</li>
            </ul>
            <a
              href="https://meetings.hubspot.com/sahirh/active-defense-workshop"
              css={primaryButton}
              target="_blank"
              rel="noreferrer"
            >
              Book the workshop
            </a>
          </div>
        </div>
        <div css={instructorSection}>
          <div css={instructorImage}>
            <img src={Sahir} alt="Sahir Hidayatullah" />
          </div>
          <p css={instructorSubtitle}>Your Instructor</p>
          <div css={instructorName}>Sahir Hidayatullah</div>
          <div css={instructorDesignation}>
            Black Hat trainer
            <br />
            on active deception for
            <br />
            red and blue teams
          </div>
        </div>
      </div>
    </div>
  )
}
