import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import smokescreenSymbol from "images/active-defense/banking-finance/smokescreen-s.png"

const wrapper = css`
  max-width: 1125px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 67px;
  padding-bottom: 67px;
  background-color: white;
  @media (max-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const heading = css`
  font-family: var(--heading);
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.31;
  color: black;
  margin-bottom: 0.75rem;
  margin-bottom: 28px;
  @media (max-width: 576px) {
    font-size: 1.7rem;
    max-width: 341px;
    margin-left: auto;
    margin-right: auto;
  }
`

const cards = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (max-width: 576px) {
    padding-bottom: 40px;
  }
`

const card = css`
  border: 1px solid #238c98;
  padding: 23px 43px;
  width: 100%;
  max-width: 341px;
  flex-shrink: 1;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  &:hover {
    div,
    p {
      color: white;
    }
    background-color: #238c98;
  }
  @media (max-width: 1023px) {
    margin-bottom: 1rem;
  }
`

const cardTitle = css`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  margin-bottom: 15px;
  color: #238c98;
  transition: color 0.2s ease;
`

const cardDesciption = css`
  margin-bottom: 0;
  line-height: 1.5;
  color: black;
  transition: color 0.2s ease;
`

const banner = css`
  display: flex;
  align-items: center;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 4px;
  padding: 25px 50px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const logo = css`
  margin-right: 100px;
  flex-shrink: 0;
  @media (max-width: 1023px) {
    margin-right: 1.5rem;
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 479px) {
    img {
      height: 64px;
    }
  }
`

const bannerContent = css`
  padding-right: 1rem;
`

const bannerTitle = css`
  font-family: var(--heading);
  color: black;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

const bannerDescription = css`
  color: black;
  opacity: 0.7;
  line-height: 1.63;
  font-size: 1rem;
  margin-bottom: 0;
  max-width: 500px;
`

const btn = css`
  display: inline-block;
  font-family: var(--heading);
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  min-width: 170px;
  padding: 11px 30px;
  border-radius: 4px;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  border: 0;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  margin-left: auto;
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
    max-width: 360px;
  }
`

const primaryButton = [
  btn,
  css`
    background-color: ${colors.orange};
    border: 1px solid ${colors.orange};
    color: white;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  `,
]

export default function Features({ openDemoModal }) {
  return (
    <div css={wrapper}>
      <div css={heading}>Consider Smokescreen if...</div>
      <div css={cards}>
        <div css={card}>
          <div css={cardTitle}>You’re a CISO</div>
          <p css={cardDesciption}>
            Who wants to reduce SIEM and per endpoint spend while successfully
            detecting the most serious threats to your business.
          </p>
        </div>
        <div css={card}>
          <div css={cardTitle}>You’re a SOC manager</div>
          <p css={cardDesciption}>
            Who wants to optimize operations, improve analyst productivity, and
            cut down your mean time to detect, know, and respond metrics.
          </p>
        </div>
        <div css={card}>
          <div css={cardTitle}>You’re a threat hunter</div>
          <p css={cardDesciption}>
            Who wants to detect the most stealthy attackers while extending
            active defense capabilities to the rest of your SOC.
          </p>
        </div>
      </div>
      <div css={banner}>
        <div css={logo}>
          <img src={smokescreenSymbol} alt="Smokescreen S" height={100} />
        </div>
        <div css={bannerContent}>
          <div css={bannerTitle}>See what Smokescreen can do for you</div>
          <p css={bannerDescription}>
            Request a tailored demo where we'll show you how you can detect
            threats and actively defend your network
          </p>
        </div>
        <button type="button" css={primaryButton} onClick={openDemoModal}>
          Get a demo
        </button>
      </div>
    </div>
  )
}
