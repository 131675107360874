import React from "react"
import { css } from "@emotion/core"

import caseStudy1 from "images/active-defense/banking-finance/casestudy-01.png"
import caseStudy2 from "images/active-defense/banking-finance/casestudy-02.png"
import caseStudy3 from "images/active-defense/banking-finance/casestudy-03.png"

const wrapper = css`
  background-image: linear-gradient(to bottom, #ffffff 59%, #f7f7f7);
  padding-top: 79px;
  padding-bottom: 100px;
  @media (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const wrapperInner = css`
  max-width: 1125px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const heading = css`
  font-family: var(--heading);
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.31;
  color: black;
  text-align: center;
  margin-bottom: 71px;
  @media (max-width: 1023px) and (min-width: 768px) {
    text-align: left;
  }
  @media (max-width: 479px) {
    font-size: 1.7rem;
    text-align: left;
    max-width: 341px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
`

const cards = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const card = css`
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
  max-width: 341px;
  flex-grow: 1;
  flex-shink: 1;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  @media (max-width: 1023px) {
    margin-bottom: 1rem;
  }
`

const cardImageWrapper = css`
  background-color: #e5f3f4;
  height: 145px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const cardContent = css`
  padding: 30px;
`

const cardTitle = css`
  color: black;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 1.5rem;
`

const cardDescription = css`
  color: black;
  opacity: 0.7;
  font-size: 1rem;
  line-height: 1.63;
  max-width: 230px;
  margin-bottom: 37px;
`

const cardLink = css`
  font-family: var(--heading);
  line-height: 19px;
  font-size: 16px;
  color: #238c98;
  font-weight: 600;
  display: inline-block;
  border: 0;
  background-color: transparent;
  border-bottom: 2px solid #238c98;
  box-shadow: none;
  padding: 0;
  padding-bottom: 4px;
  &:focus {
    outline: none;
  }
`

export default function BankCaseStudies({ data, openResourceModal }) {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={heading}>
          Trusted by banks and financial institutions, globally
        </div>
        <div css={cards}>
          <div css={card}>
            <div css={cardImageWrapper}>
              <img src={caseStudy1} alt="" />
            </div>
            <div css={cardContent}>
              <div css={cardTitle}>
                Bank mitigates risk from 3rd party ATM vendor
              </div>
              <div css={cardDescription}>
                Decoys planted in the critical server segment intercepted
                malware attempting to propagate from ATMs.
              </div>
              <button
                type="button"
                css={cardLink}
                onClick={() =>
                  openResourceModal({
                    ...data.atm_case_study,
                    heading: "Get the case study",
                  })
                }
              >
                Download the case study
              </button>
            </div>
          </div>
          <div css={card}>
            <div css={cardImageWrapper}>
              <img src={caseStudy2} alt="" />
            </div>
            <div css={cardContent}>
              <div css={cardTitle}>Leading bank stops lateral movement</div>
              <div css={cardDescription}>
                Decoys mimicking SWIFT servers, ATMs and cardholder databases
                detected lateral movement scans.
              </div>
              <button
                type="button"
                css={cardLink}
                onClick={() =>
                  openResourceModal({
                    ...data.bank_case_study,
                    heading: "Get the case study",
                  })
                }
              >
                Download the case study
              </button>
            </div>
          </div>
          <div css={card}>
            <div css={cardImageWrapper}>
              <img src={caseStudy3} alt="" />
            </div>
            <div css={cardContent}>
              <div css={cardTitle}>
                World’s fastest stock exchange adopts active defense
              </div>
              <div css={cardDescription}>
                Decoys deployed in VLANs, decoy credentials, and perimeter
                decoys proactively detect and respond to attacks.
              </div>
              <button
                type="button"
                css={cardLink}
                onClick={() =>
                  openResourceModal({
                    ...data.bse_case_study,
                    heading: "Get the case study",
                  })
                }
              >
                Download the case study
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
